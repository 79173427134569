import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class FooterSeven extends Component {
  render () {
    const { logo = 'assets/images/demos/demo-6/logo-footer.png' } = this.props
    return (
      <footer className='footer'>
        <div className='footer-middle'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6 col-lg-3'>
                <div className='widget widget-about'>
                
                  <div className='social-icons'>
                    <a href='https://www.facebook.com/Mahoganyacc123' rel='noopener noreferrer' className='social-icon' target='_blank' title='Facebook'><i className='icon-facebook-f' /></a>
                    <a href='https://www.instagram.com/mahoganysclassycloset/' rel='noopener noreferrer' className='social-icon' target='_blank' title='Instagram'><i className='icon-instagram' /></a> 
                  </div>
                </div>
              </div>


              <div className='col-sm-6 col-lg-3'>
                <div className='widget'>
                  <h4 className='widget-title'>My Account</h4>

                  <ul className='widget-list'>
                    <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=account`}>Sign In</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/shop/cart`}>View Cart</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/pages/faq`}>Help</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='footer-bottom'>
          <div className='container'>
            <figure className='footer-payments'>
              <img src={`${process.env.PUBLIC_URL}/assets/images/payments.png`} alt='Payment methods' width='272' height='20' />
            </figure>
            {/* <img src={process.env.PUBLIC_URL + '/' + logo} alt='Molla Logo' width='82' height='25' /> */}
            <p className='footer-copyright'>Copyright © {(new Date()).getFullYear()} MAHOGANY Store. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    )
  }
}

export default FooterSeven
