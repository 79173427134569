import axios from 'axios'

const apiUrl = process.env.REACT_APP_ECWID_API
const token = process.env.REACT_APP_PUBLIC_TOKEN
const storeId= process.env.REACT_APP_STORE_ID

var requestURL = `${apiUrl}/${storeId}/products?token=${token}`

// API to get products from mock server
export const getProducts = function () {
  return axios.get(requestURL)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      // handle error
      console.log(error)
    })
}

// API to get posts from mock server
export const getPosts = function () {

}
