import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { quantityInputs, isIEBrowser, isEdgeBrowser } from '../../../../utils/utils'

class ProductDetailOne extends Component {
  componentDidMount () {
    quantityInputs()
  }

  render () {
    const { adClass = '', type = 'default', product, onAddToCartClick, onToggleWishlistClick, onAddToCompareClick } = this.props

    return (
      <div className={`product-details ${adClass}`}>
        <h1 className='product-title'>{product.name}</h1>
{/* 
        <div className='ratings-container'>
          <div className='ratings'>
            <div className='ratings-val' style={{ width: product.ratings * 20 + '%' }} />
          </div>
          <Link className='ratings-text' to='#product-review-link' id='review-link'>( {product.reviews} Reviews )</Link>
        </div> */}

        {!product.inStock
          ? <div className='product-price'>
            <span className='out-price'>${product.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            </div>

          : product.discount > 0
            ? <div className='product-price'>
              <span className='new-price'>${product.salePrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
              <span className='old-price'>${product.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
              </div>
            : <div className='product-price'>${product.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>}

        <div className='product-content'>
          <p>{product.shortDesc}</p>
        </div>

        {product.variants
          ? <div className='details-filter-row details-row-size'>
            <label>Color:</label>
            {product.variants[0].rgb
              ? <div className='product-nav product-nav-dots'>
                {
                                    product.variants.map((vari, i) =>
                                      <Link to='#' key={i} className={i === 0 ? 'active' : ''} style={{ backgroundColor: vari.rgb }} />
                                    )
                                }
                </div>
              : <div className='product-nav product-nav-thumbs'>
                {
                                    product.variants[0].model
                                      ? product.variants.map((vari, i) =>
                                        <Link to='#' key={i} className={i === 0 ? 'active' : ''}>
                                          <img src={process.env.PUBLIC_URL + '/' + vari.model} alt='product desc' />
                                        </Link>
                                        )
                                      : product.variants[0].image
                                        ? product.variants.map((vari, i) =>
                                          <Link to='#' key={i} className={i === 0 ? 'active' : ''}>
                                            <img src={process.env.PUBLIC_URL + '/' + vari.image} alt='product desc' />
                                          </Link>
                                          ) : ''
                                }
                </div>}
            </div> : ''}
        {/* <div className='details-filter-row details-row-size'>
          <label htmlFor='size'>Size:</label>
          <div className='select-custom'>
            <select name='size' id='size' className='form-control' defaultValue='#'>
              <option value='#'>Select a size</option>
              <option value='s'>Small</option>
              <option value='m'>Medium</option>
              <option value='l'>Large</option>
              <option value='xl'>Extra Large</option>
            </select>
          </div>

          {type === 'default'
            ? <Link to='#' className='size-guide'><i className='icon-th-list' />size guide</Link>
            : ''}
        </div> */}

        <div className='details-filter-row details-row-size'>
          <label htmlFor='qty'>Qty:</label>
          <div className='product-details-quantity'>
            <input type='number' id='qty' className='form-control' defaultValue='1' min='1'  step='1' data-decimals='0' required />
          </div>
        </div>

        <div className='product-details-action'>
          {isIEBrowser() || isEdgeBrowser()
            ? <button className='btn-product btn-cart' onClick={() => onAddToCartClick(product, document.querySelector('#qty').value)} style={{ minHeight: '4rem' }}><span>add to cart</span></button>
            : <button className='btn-product btn-cart' onClick={() => onAddToCartClick(product, document.querySelector('#qty').value)}><span>add to cart</span></button>}
          {isIEBrowser() || isEdgeBrowser()
            ? <div className='details-action-wrapper IE-detail-action-wrapper'>
              <button className='btn-product btn-wishlist pl-0 pr-0' title='Wishlist' onClick={() => onToggleWishlistClick(product)} style={{ minWidth: '127px' }}><span>Add to Favorite</span></button>
            </div>
            : <div className='details-action-wrapper'>
              <button className='btn-product btn-wishlist pl-0 pr-0' title='Wishlist' onClick={() => onToggleWishlistClick(product)}><span>Add to Favorite</span></button>
            </div>}
        </div>

        <div className='product-details-footer'>
          {/* <div className='product-cat'>
            <span>Category: </span>
            {product.categories.map((cat, index) => (
              <span key={index} className='mr-0'>
                <Link to='#'>{cat}</Link>
                {index < product.categories.length - 1 ? ', ' : ''}
              </span>
            ))}
          </div> */}

          <div className='social-icons social-icons-sm'>
            <span className='social-label'>Share:</span>
            <Link to='#' className='social-icon' title='Facebook' target='_blank'><i className='icon-facebook-f' /></Link>
            <Link to='#' className='social-icon' title='Twitter' target='_blank'><i className='icon-twitter' /></Link>
            <Link to='#' className='social-icon' title='Instagram' target='_blank'><i className='icon-instagram' /></Link>
            <Link to='#' className='social-icon' title='Pinterest' target='_blank'><i className='icon-pinterest' /></Link>
          </div>
        </div>

        {this.props.children}
      </div>
    )
  }
}

export default ProductDetailOne
