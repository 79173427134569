import React, { Component } from 'react'
import { connect } from 'react-redux'

class OuterOverlay extends Component {
  render () {
    const { isLoading } = this.props
    return (
      <>
        {
                isLoading
                  ? <div className='loading-overlay'>
                    <div className='root-loader bounce-loader'>
                      <div className='bounce1' />
                      <div className='bounce2' />
                      <div className='bounce3' />
                    </div>
                    </div>
                  : ''
            }
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.overlay.isOuterLoading
  }
}

export default connect(mapStateToProps)(OuterOverlay)
