export const featureSlider1 = {
  nav: false,
  dots: false,
  margin: 30,
  loop: false,
  responsive: {
    0: {
      items: 2
    },
    420: {
      items: 3
    },
    600: {
      items: 4
    },
    900: {
      items: 5
    },
    1024: {
      items: 6
    }
  }
}

export const featureSlider2 = {
  nav: false,
  dots: false,
  margin: 0,
  loop: false,
  responsive: {
    0: {
      items: 2
    },
    420: {
      items: 3
    },
    600: {
      items: 4
    },
    900: {
      items: 5
    },
    1024: {
      items: 6
    },
    1360: {
      items: 7
    }
  }
}

export const featureSlider3 = {
  nav: false,
  dots: false,
  margin: 0,
  items: 6,
  loop: false,
  responsive: {
    0: {
      items: 1
    },
    360: {
      items: 2
    },
    600: {
      items: 3
    },
    992: {
      items: 4
    },
    1200: {
      items: 5
    },
    1500: {
      items: 6
    }
  }
}

export const featureSlider4 = {
  nav: false,
  dots: false,
  margin: 20,
  loop: false,
  responsive: {
    0: {
      items: 2
    },
    480: {
      items: 2
    },
    768: {
      items: 3
    },
    992: {
      items: 4
    },
    1200: {
      items: 5
    }
  }
}
