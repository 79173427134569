import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

// import Custom Components
import PageHeader from '../../common/page-header'
import Breadcrumb from '../../common/breadcrumb'
import Accordion from '../../features/accordion/accordion'
import Card from '../../features/accordion/card'

import { isIEBrowser } from '../../../utils/utils'

const cardData = [{
  title:'How will my parcel be delivered?',
  message: 'In a timely fashion with affordable rates.'
},
{
  title:'Do I pay for delivery?',
  message: 'In most cases yes, except for special promotions.'
},
{
  title:'Will I be charged customs fees?',
  message: 'Yes'
},
{
  title:'My item has become faulty',
  message: 'We do not offer refunds but you can exchange your item(s) with a receipt.'
}]

class FAQ extends Component {
  render () {
    return (
      <div className='main'>
        <PageHeader title='F.A.Q' subTitle='Pages' />
        <div className='page-content'>
          <div className='container'>
            <h2 className='title text-center mb-3'>Shipping Information</h2>

            <Accordion adClass='accordion-rounded'>

              {cardData.map(({title, message}) => (
                <Card title={title} message={message} adClass='card-box card-sm bg-light'/>
              ))
              }

            </Accordion>

            <h2 className='title text-center mb-3'>Orders and Returns</h2>

            <Accordion adClass='accordion-rounded'>
              <Card title='Tracking my order' adClass='card-box card-sm bg-light'>
                You will receive an email with tracking information.
              </Card>

              <Card title='I haven’t received my order' adClass='card-box card-sm bg-light'>
                You can contact us via phone or email for more information.
              </Card>

              <Card title='How can I return an item?' adClass='card-box card-sm bg-light'>
                Through USPS or your method of choice.
              </Card>
            </Accordion>

            <h2 className='title text-center mb-3'>Payments</h2>

            <Accordion adClass='accordion-rounded'>
              <Card title='What payment types can I use?' adClass='card-box card-sm bg-light'>
                We offer over 20 types of payment methods including Square stripe klarna visa apple pay etc.
              </Card>

              <Card title='Can I pay by Gift Card?' adClass='card-box card-sm bg-light'>
                We do sell and accept gift cards.
              </Card>

              <Card title="I can't make a payment" adClass='card-box card-sm bg-light'>
                Please send us an email via the contact page with the error you are recieving when making a payment.
                <br></br><Link to={`${process.env.PUBLIC_URL}/pages/contact`}>Contact Us</Link>     
              </Card>

              <Card title='Has my payment gone through?' adClass='card-box card-sm bg-light'>
                You will receive a confirmation email if it has.
              </Card>
            </Accordion>
          </div>
        </div>

        <div className='cta cta-display bg-image pt-4 pb-4' style={{ backgroundImage: 'url(assets/images/backgrounds/cta/bg-7.jpg)' }}>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-10 col-lg-9 col-xl-7'>
                <div className={`row no-gutters ${isIEBrowser() ? '' : 'flex-column'} flex-sm-row align-items-sm-center`}>
                  <div className='col'>
                    <h3 className='cta-title text-white'>If You Have More Questions</h3>
                  </div>

                  <div className='col-auto'>
                    <Link to={`${process.env.PUBLIC_URL}/pages/contact`} className='btn btn-outline-white'><span>CONTACT US</span><i className='icon-long-arrow-right' /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FAQ
