import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { removeFromCompare, resetCompare } from '../../../../../actions'
import { safeContent } from '../../../../../utils/utils'

class CompareMenu extends Component {
  render () {
    const { compareList, removeFromCompare, resetCompare } = this.props
    return (
      <div className='dropdown compare-dropdown'>
        <a href='#dropdown' className='dropdown-toggle' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' data-display='static' title='Compare Products' aria-label='Compare Products'>
          <div className='icon'>
            <i className='icon-random' />
          </div>
        </a>
        <div className='dropdown-menu dropdown-menu-right'>
          <ul className='compare-products'>
            {compareList.map((item, index) => (
              <li className='compare-product' key={index}>
                <button className='btn-remove' title='Remove Product' onClick={() => removeFromCompare(item)}><i className='icon-close' /></button>
                <h4 className='compare-product-title'><Link to={`${process.env.PUBLIC_URL}/product/default/27`} dangerouslySetInnerHTML={safeContent(item.name)} /></h4>
              </li>
            ))}
            {
                            compareList.length === 0 ? <p className='mb-1'>No products in the compare.</p> : ''
                        }
          </ul>
          <div className='compare-actions'>
            <button className='action-link' onClick={() => resetCompare()}>Clear All</button>
            <Link to='#' className='btn btn-outline-primary-2'><span>Compare</span><i className='icon-long-arrow-right' /></Link>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    compareList: state.compare.items ? state.compare.items : []
  }
}
export default connect(mapStateToProps, { removeFromCompare, resetCompare })(CompareMenu)
