import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as LO } from "./logo.svg"

class Logo extends Component {
  render () {
    const { width = '130', height = '40', svg } = this.props

    return (
      !svg ? <Link to={`${process.env.PUBLIC_URL}/`} className={`logo top-left`}>
        <img src={`${process.env.PUBLIC_URL}/assets/images/${this.props.logo}`} alt='Mahagonnay Logo' width={width} height={height} />
      </Link>: <LO />
    )
  }
}

export default Logo
