// Overlay
export const INNER_LOADING = 'INNER_LOADING'
export const OUTER_LOADING = 'OUTER_LOADING'

// Posts
export const RECEIVE_POSTS = 'RECEIVE_POSTS'

// Get Products
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'

// Quick View
export const SHOW_QUICKVIEW = 'SHOW_QUICKVIEW'
export const CLOSE_QUICKVIEW = 'CLOSE_QUICKVIEW'

// Cart
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const CHANGE_QTY = 'CHANGE_QTY'
export const CHANGE_SHIPPING = 'CHANGE_SHIPPING'
export const FETCH_CART = 'FETCH_CART'

// WishList
export const TOGGLE_WISHLIST = 'TOGGLE_WISHLIST'
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST'

// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE'
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE'
export const RESET_COMPARE = 'RESET_COMPARE'

// Product Filters
export const SORT_BY = 'SORT_BY'
export const CATEGORY_FILTER = 'CATEGORY_FILTER'
export const RESET_FILTER = 'RESET_FILTER'
export const SIZE_FILTER = 'SIZE_FILTER'
export const COLOR_FILTER = 'COLOR_FILTER'
export const BRAND_FILTER = 'BRAND_FILTER'
export const PRICE_FILTER = 'PRICE_FILTER'
export const RATING_FILTER = 'RATING_FILTER'

// Newsletter
export const HIDE_NEWSLETTER_MODAL = 'HIDE_NEWSLETTER_MODAL'

// Modal
export const SHOW_MODAL = 'SHOW_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

// Demo
export const CHANGE_DEMO = 'CHANGE_DEMO'
