import { findIndex } from '../utils/utils'

/**
 * Get visible products
 *
 * @param {Array} products
 * @param {Object} param1
 */
export const getVisibleProducts = (products, { sortBy, category, size, brand, color, rating, value }) => {
  return products.filter(item => {
    let catResult = false; let sizeResult = false; let brandResult = false; let colorResult = false; let valResult = false; let ratingResult = false

    if (category && category.length > 0) {
      for (let i = 0; i < category.length; i++) {
        if (findIndex(item.category, cat => cat === category[i]) !== -1 || (category[i] === 'Sale' && item.discount > 0) || (category[i] === 'All')) { catResult = true }
      }
    } else { catResult = true }

    if (size && size.length > 0) {
      for (let i = 0; i < size.length; i++) {
        if (findIndex(item.size, sz => sz === size[i]) !== -1) { sizeResult = true }
      }
    } else { sizeResult = true }

    if (brand && brand.length > 0) {
      for (let i = 0; i < brand.length; i++) {
        if (findIndex(item.brands, br => br === brand[i]) !== -1) { brandResult = true }
      }
    } else { brandResult = true }

    if (color && color.length > 0) {
      for (let i = 0; i < color.length; i++) {
        if (findIndex(item.variants, cl => cl.rgb === color[i]) !== -1) { colorResult = true }
      }
    } else { colorResult = true }

    if (rating && rating.length > 0) {
      for (let i = 0; i < rating.length; i++) {
        if (item.ratings === rating[i]) { ratingResult = true }
      }
    } else { ratingResult = true }

    const price = item.discount ? item.salePrice : item.price

    if (value && value.min <= price && price <= value.max) { valResult = true }

    return catResult && sizeResult && brandResult && colorResult && valResult && ratingResult
  }).sort((product1, product2) => {
    const price1 = product1.discount > 0 ? product1.salePrice : product1.price
    const price2 = product2.discount > 0 ? product2.salePrice : product2.price
    if (sortBy === 'popularity') {
      return product2.id < product1.id ? -1 : 1
    } else if (sortBy === 'rating') {
      return product2.ratings > product1.ratings ? 1 : -1
    } else if (sortBy === 'date') {
      return product2.created > product1.created ? -1 : 1
    } else if (sortBy === 'newness') {
      return product2.id > product1.id ? -1 : 1
    } else if (sortBy === 'price: low to high') {
      return price2 > price1 ? -1 : 1
    } else if (sortBy === 'price: high to low') {
      return price2 < price1 ? -1 : 1
    } else {
      return product2.id < product1.id ? -1 : 1
    }
  })
}

/**
 * Get featured products
 * @param {Array} products
 * @return {Array} featuredProducts
 */
export const getFeaturedProducts = (products) => {
  return products.filter(item => item.featured === true)
}

/**
 * Get sold products
 * @param {Array} products
 * @return {Array} saleProducts
 */
export const getSaleProducts = (products) => {
  return products.filter(item => item.discount > 0)
}

/**
 * Get recommended products
 * @param {Array} products
 * @return {Array} recommendedProducts
 */
export const getRecommendedProducts = (products) => {
  return products.filter(item => item.recommend)
}

/**
 * Get sidebar products
 * @param {Array} products
 * @return {Array} sidebarProducts
 */
export const getSidebarProducts = (products) => {
  return products.filter(item => item.sidebar)
}

/**
 * Get trendy products
 * @param {Array} products
 * @return {Array} trendyProducts
 */
export const getTrendyProducts = (products) => {
  return products.filter(item => item.trendy)
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
 function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

/**
 * Get new products
 * @param {Array} products
 * @return {Array} newProducts
 */
export const getNewProducts = (products) => {
  return shuffle((products || []).filter(item => item.enabled))
}

/**
 * Get deal products
 * @param {Array} products
 * @return {Array} dealProducts
 */
export const getDealProducts = (products, deal) => {
  return products.filter(item => item.deal === deal)
}

/**
 * Get products which has top rating
 * @param {Array} products
 * @return {Array} saleProducts
 */
export const getTopRatingProducts = (products) => {
  return products.filter(product => {
    return product.ratings > 2
  }).sort((product1, product2) => {
    return product2.ratings < product1.ratings ? -1 : 1
  })
}

/**
 * Get products which has top sale
 * @param {Array} products
 * @return {Array} topProducts
 */
export const getTopSellingProducts = (products) => {
  return products.filter(item => item.top === true)
}

/**
 * Get products filtered by demo
 * @param {Array} products
 * @param {String} demo
 * @return {Array} filtered products
 */
export const getProductsByDemo = (products, demo) => {
  return products.filter(item => item.demo ? item.demo.indexOf(demo) > -1 : false)
}

/**
 * Get products filtered by category
 * @param {Array} products
 * @param {String} category
 * @return {Array}
 */
export const getProductsByCategory = (products, category) => {
  if (category === 'All') return products

  if (category.indexOf('&') !== -1) {
    category = category.split(' & ')
  }

  return products.filter(item => {
    let result = false
    if (Array.isArray(category)) {
      for (let i = 0; i < category.length; i++) {
        if (findIndex(item.category, cat => cat === category[i]) !== -1) {
          result = true
        }
      }
    } else {
      if (findIndex(item.category, cat => cat === category) !== -1) { result = true }
    }
    return result
  })
}

/**
 * Get number of products filtered by category
 * @param {Array} products
 * @param {String} category
 * @return {Integer}
 */
export const getCountByCategory = (products, category) => {
  if (category === 'All') return products.length
  if (category === 'Sale') return products.filter(item => item.discount > 0).length
  return products.items.filter(item => findIndex(item.category, cat => category === cat) !== -1).length
}

/**
 * get total Price of products in cart.
 * @param {Array} cartItems
 */
export const getCartTotal = cartItems => {
  let total = 0
  for (let i = 0; i < cartItems.length; i++) {
    total += parseInt(cartItems[i].quantity, 10) * (cartItems[i].price)
  }
  return total
}

/**
 * get number of products in cart
 * @param {Array} cartItems
 * @return {Integer} total
 */
export const getCartCount = cartItems => {
  return cartItems.length > 0 ? cartItems.length : 0
}

/**
 * Get number of products filtered by rating
 * @param {Array} products
 * @param {String} category
 * @return {Integer}
 */
export const getCountByRating = (products, rating) => {
  return products.items.filter(item => item.rating === rating).length
}
