import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Breadcrumb from '../../common/breadcrumb'

class ContactOne extends Component {
  render () {
    return (
      <div className='main'>
        <Helmet>
          <title>Mahogany - Contact Page</title>
        </Helmet>

        <h1 className='d-none'> Contact Page</h1>

        <Breadcrumb title='Contact Us' parent1={['pages', 'pages/about']} adClass='border-0 mb-0' />

        <div className='container'>
          <div className='page-header page-header-big text-center' style={{ backgroundImage: 'url(\'assets/images/contact-header-bg.jpg\')' }}>
            <h1 className='page-title text-white'>Contact us<span className='text-white'>keep in touch with us</span></h1>
          </div>
        </div>

        <div className='page-content pb-0'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <h2 className='title mb-1'>Got Any Questions?</h2>
                <p className='mb-2'>Use the form below to get in touch with the sales team</p>

                <form action='#' className='contact-form mb-3'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <label htmlFor='cname' className='sr-only'>Name</label>
                      <input type='text' className='form-control' id='cname' placeholder='Name *' required />
                    </div>

                    <div className='col-sm-6'>
                      <label htmlFor='cemail' className='sr-only'>Email</label>
                      <input type='email' className='form-control' id='cemail' placeholder='Email *' required />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-6'>
                      <label htmlFor='cphone' className='sr-only'>Phone</label>
                      <input type='tel' className='form-control' id='cphone' placeholder='Phone' />
                    </div>

                    <div className='col-sm-6'>
                      <label htmlFor='csubject' className='sr-only'>Subject</label>
                      <input type='text' className='form-control' id='csubject' placeholder='Subject' />
                    </div>
                  </div>

                  <label htmlFor='cmessage' className='sr-only'>Message</label>
                  <textarea className='form-control' cols='30' rows='4' id='cmessage' required placeholder='Message *' />

                  <button type='submit' className='btn btn-outline-primary-2 btn-minwidth-sm'>
                    <span>SUBMIT</span>
                    <i className='icon-long-arrow-right' />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactOne
