import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// import Custom Components
import Breadcrumb from '../../common/breadcrumb'
import ProfileOne from '../../features/profile/profile-one'
import OwlCarousels from '../../features/owl-carousel'
import Testimonial from '../../features/testimonial/testimonial'

// import Utils
import { mainSlider5 } from '../settings'

import _data from '../../../mock_data/data.json'

class AboutOne extends Component {
  componentDidMount() {
    document.querySelector('.footer-middle').classList.add('border-0')
  }

  render() {
    return (
      <div className='main'>
        <Helmet>
          <title>About Page</title>
        </Helmet>

        <div className='container'>
          <div className='page-header page-header-big text-center' style={{ backgroundImage: 'url(\'assets/images/about-header-bg.jpg\')' }}>
            <h1 className='page-title text-white'>About Us<span className='text-white'>Who we are</span></h1>
          </div>
        </div>

        <div className='page-content pb-0'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 mb-3 mb-lg-0'>
                <h2 className='title'>Our Vision</h2>
                <p>To be successful in providing unsurpassed customer service and have our customers return with a smile. </p>
              </div>

              <div className='col-lg-6'>
                <h2 className='title'>Our Mission</h2>
                <p>To bring reasonable prices and fashionable styles that suit everyone's lifestyle. </p>
              </div>
            </div>

            <div className='mb-5' />
          </div>

          <div className='bg-light-2 pt-6 pb-5 mb-6 mb-lg-8'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-5 mb-3 mb-lg-0'>
                  <h2 className='title'>Who We Are</h2>
                  <p className='lead text-primary mb-3'>Mahogany's Classy Closet is a high end to moderate shop for coats, clothing, purses, jewelry, and accessories.</p>
                  <p className='mb-2'>We're taking our entire inventory online for the very first time! We know people have been hit with hard times as a result of the pandemic, and access to luxury items has become limited with store closures. That's why we're taking the caring touch of a family-run business and incorporating the ease of access granted by ecommerce.Our founder, Josie,  has decades of experience in the field, having been the owner of a retail store previously, and always working to ensure the highest quality products in this carefully curated collection. Our core values are the pursuit of black excellence, family ties, and the belief that everyone deserves to look their best.</p>

                  <Link to={`${process.env.PUBLIC_URL}/blog/classic`} className='btn btn-sm btn-minwidth btn-outline-primary-2'>
                    <span>VIEW OUR SOCIAL MEDIA</span>
                    <i className='icon-long-arrow-right' />
                  </Link>
                </div>

                <div className='col-lg-6 offset-lg-1'>
                  <div className='about-images'>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/about/img-1.jpg`} alt='' className='about-img-front' />
                    <img src={`${process.env.PUBLIC_URL}/assets/images/about/img-2.jpg`} alt='' className='about-img-back' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AboutOne
