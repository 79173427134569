import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
// import Custom Components
import PageHeader from '../../common/page-header'
import Breadcrumb from '../../common/breadcrumb'

import { getCartTotal } from '../../../services'
import { quantityInputs, isIEBrowser } from '../../../utils/utils'
import { changetQty, removeFromCart, changeShipping, fetchEcwidCart } from '../../../actions'

class Cart extends Component {
  constructor (props) {
    super(props)
    this.state = {
      shipping: this.props.shipping
    }
    this.onChangeShipping = this.onChangeShipping.bind(this)
    this.onChangeQty = this.onChangeQty.bind(this)
    this.goToCheckout = this.goToCheckout.bind(this)
  }

  componentDidMount () {
    quantityInputs()
  }

  onChangeShipping (val) {
    this.setState({ shipping: val })
  }

  onChangeQty (e, product_id) {
    this.props.changetQty(product_id, e.currentTarget.querySelector('input[type="number"]').value)
  }

  goToCheckout () {
    this.props.changeShipping(this.state.shipping)
  }

  render () {
    const { cartlist, total, removeFromCart } = this.props
    const shippingPrice = { free: 0, standard: 10, express: 20 }

    return (
      <div className='main'>

        <PageHeader title='Shopping Cart' subTitle='Shop' />
        <Breadcrumb title='Shopping Cart' parent1={['Shop', 'shop/sidebar/list']} />

        <div className='page-content'>
          <div className='cart'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-9'>
                  <table className='table table-cart table-mobile'>
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        <th />
                      </tr>
                    </thead>

                    <tbody>
                      {cartlist.length > 0
                        ? cartlist.map((item, index) => {
                          const sum = parseInt(item.quantity, 10) * (item.price)
                          return (
                          <tr key={index}>
                            <td className='product-col'>
                              <div className='product'>
                                <figure className='product-media'>
                                  <Link to={`${process.env.PUBLIC_URL}/product/default/${item.id}`}>
                                    <img src={item.originalImageUrl} alt='Product' />
                                  </Link>
                                </figure>

                                <h3 className='product-title'>
                                  <Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=${item.name.split(" ").join("-")}-p${item.productId}`}>{item.name}</Link>
                                </h3>
                              </div>
                            </td>

                            <td className='price-col'>
                              ${item.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </td>

                            <td className='quantity-col'>
                              {item.quantity}
                            </td>

                            <td className='total-col'>
                              ${sum.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </td>
                          </tr>
                          )
                        })
                        : <p className='pl-2 pt-1 pb-1'> No Products in Cart </p>}

                    </tbody>
                  </table>
                </div>
                <aside className='col-lg-3'>
                  <div className='summary summary-cart'>
                    <Link
                      to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=cart`}
                      className='btn btn-outline-primary-2 btn-order btn-block'
                      onClick={this.goToCheckout}
                    >
                      PROCEED TO CHECKOUT
                    </Link>
                  </div>

                  <Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth`} className='btn btn-outline-dark-2 btn-block mb-3'><span>CONTINUE SHOPPING</span><i className='icon-refresh' /></Link>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state) => ({
  cartlist: state.cartlist.cart,
  total: getCartTotal(state.cartlist.cart),
  shipping: state.cartlist.shipping
})

export default connect(mapStateToProps, { changetQty, removeFromCart, changeShipping, fetchEcwidCart })(Cart)
