import React, { Component } from 'react'
import { connect } from 'react-redux'

class InnerOverlay extends Component {
  render () {
    return (
      <>
        {
                this.props.isLoading
                  ? <div className='loading-overlay'>
                    <div className='bounce-loader'>
                      <div className='bounce1' />
                      <div className='bounce2' />
                      <div className='bounce3' />
                    </div>
                    </div>
                  : ''
            }
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.overlay.isInnerLoading
  }
}

export default connect(mapStateToProps)(InnerOverlay)
