import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import MobileMainNav from './nav'

class MobileMenu extends Component {
  render () {
    const { adClass } = this.props

    return (

      <div className={`mobile-menu-container ${adClass}`}>
        <div className='mobile-menu-wrapper'>
          <span className='mobile-menu-close'><i className='icon-close' /></span>

          <nav className='mobile-nav'>
            <MobileMainNav />
          </nav>
                
          <div className='social-icons'>
            <a href='https://www.facebook.com/Mahoganyacc123' rel='noopener noreferrer' className='social-icon' target='_blank' title='Facebook'><i className='icon-facebook-f' /></a>
            <a href='https://www.instagram.com/mahoganysclassycloset/' rel='noopener noreferrer' className='social-icon' target='_blank' title='Instagram'><i className='icon-instagram' /></a> 
          </div>
        </div>
      </div>
    )
  }
}

export default MobileMenu