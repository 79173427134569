import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import BaseMenu from './base'

class MainMenu extends BaseMenu {
  render() {
    const { demo } = this.props
    return (
      <nav className='main-nav' onClick={this.activeNav}>
        <ul className='menu sf-arrows'>
          <li className='megamenu-container active'>
            <Link to={`${process.env.PUBLIC_URL}/`} className='sf-with-ul none'>Home</Link>
          </li>
          <li>
            <Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth`} className='sf-with-ul'>Shop</Link>

            <div className='megamenu megamenu-sm'>
              <div className='row no-gutters'>
                <div className='col-md-8'>
                  <div className='menu-col'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='menu-title'>Shop</div>
                        <ul>
                          <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth`}>All Products</Link></li>
                          <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Pewter-%26-etc-c72577765`}>Pewter & etc</Link></li>
                          <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Earrings-c72605401`}>Earings</Link></li>
                          <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Jewelry-c72604429`}>Jewelry</Link></li>
                          <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Coats-Jackets-c72645143`}>Coats/Jackets</Link></li>
                        </ul>
                      </div>
                      <div className='col-md-6'>
                        <ul>
                          <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Purses-c73654125`}>Purses</Link></li>
                          <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Clothing-c106531038`}>Clothing</Link></li>
                          <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Bargain-Closet-$10-and-under-c121708263`}>$10 and Under</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='col-md-4'>
                  <div className='banner banner-overlay'>
                    <Link to={`${process.env.PUBLIC_URL}/shop/sidebar/list`} className='banner banner-menu'>
                      <img src={`${process.env.PUBLIC_URL}/assets/images/menu/banner-1.jpg`} alt='Banner' />

                      <div className='banner-content banner-content-top'>
                        <div className='banner-title text-white'>Last <br />Chance<br /><span><strong>Sale</strong></span></div>
                      </div>
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </li>
          <li>
            <Link to={`${process.env.PUBLIC_URL}/pages/about`} className='sf-with-ul'>Pages</Link>

            <ul>
              <li>
                <Link to={`${process.env.PUBLIC_URL}/pages/about`} className=''>About</Link>
              </li>
              <li>
                <Link to={`${process.env.PUBLIC_URL}/pages/contact`} className=''>Contact</Link>
              </li>
              <li><Link to={`${process.env.PUBLIC_URL}/pages/faq`}>FAQs</Link></li>
              {/* <li><Link to={`${process.env.PUBLIC_URL}/pages/404`}>Error 404</Link></li>
              <li><Link to={`${process.env.PUBLIC_URL}/pages/coming-soon`}>Coming Soon</Link></li> */}
            </ul>
          </li>
        </ul>
      </nav>
    )
  }
}

export const mapStateToProps = (state) => ({
  demo: state.demo.current
})

export default connect(mapStateToProps)(MainMenu)
