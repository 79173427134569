import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify'
import { Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

import store from '../../../store'

// import Custom Components
import SideBar from '../../features/sidebar/shop-filter'
import HeaderOne from '../../common/header/header-1'
import MobileMenu from '../../common/header/common/mobile-menus/menu-1'
import FooterOne from '../../common/footer/footer-one'
import FooterFour from '../../common/footer/footer-four'
import OuterOveraly from '../../common/overlay/outer-overlay'
import ProductListTwo from '../../features/product/common/product-list-two'
import ErrorPage from '../../main/pages/404'
import LayOut from '../../app'
import {ProductBrowser} from '@ecwid/nextjs-ecwid-plugin'


import { initSettings } from '../../../utils/utils'
import { outerLoading, innerLoading } from '../../../actions'

class NoSideBar extends Component {
  componentDidMount () {
    initSettings()
    store.dispatch(outerLoading())
  }

  componentDidUpdate () {
    if (store.getState() && store.getState().overlay.type === 'inner') {
      store.dispatch(innerLoading())
    }
  }

  componentWillUnmount () {
    store.dispatch(outerLoading())
  }

  hideSideBar () {
    if (document.querySelector('body').classList.contains('sidebar-filter-active')) { document.querySelector('body').classList.remove('sidebar-filter-active') }
  }

  render () {
    const type = this.props.match.params.type
    const title = { boxed: 'Boxed No Sidebar', fullwidth: 'All Products' }
    const bread = { boxed: 'Boxed', fullwidth: 'Fullwidth' }

    if (type !== 'boxed' && type !== 'fullwidth') {
      return (
        <LayOut>
          <ErrorPage />
        </LayOut>
      )
    }

    return (
      <>
        <OuterOveraly />
        <div className='page-wrapper'>
          <HeaderOne container={type === 'boxed' ? 'container' : 'container-fluid'} />
          <div className='main'>
            <div className='page-header text-center' style={{ backgroundImage: 'url(\'assets/images/page-header-bg.jpg\')' }}>
              <div className={type === 'boxed' ? 'container' : 'container-fluid'}>
                <h1 className='page-title'>{title[type]}<span>Shop</span></h1>
              </div>
            </div>

            <div className='page-content'>
            <ProductBrowser
              storeId="44245055"
            />
            </div>
          </div>
          {/* {
                        type === 'boxed'
                          ? <FooterOne />
                          : type === 'fullwidth'
                            ? <FooterFour /> : ''
                    } */}
                    <FooterOne />
          <ToastContainer autoClose={3000} className='toast-container' />
        </div>

        <MobileMenu />
      </>
    )
  }
}

export default NoSideBar
