import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// import Custom Components
import Logo from '../logo'
import MainMenu from './common/main-menus/menu-1'
import CartMenu from './common/cart-menus/menu-2'

import { showModal } from '../../../actions'
import { isIEBrowser, stickyHeaderHandler } from '../../../utils/utils'

class HeaderEight extends Component {
  constructor (props) {
    super(props)
    this.openLoginModal = this.openLoginModal.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', stickyHeaderHandler)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', stickyHeaderHandler)
  }

  openLoginModal (e) {
    this.props.showModal('login')
    e.preventDefault()
  }

  render () {
    return (
      <header className='header header-6'>
        <div className='header-top'>
          <div className='container'>
            <div className='header-left'>
              <ul className='top-menu top-link-menu d-none d-md-block'>
                <li>
                  <Link to='#'>Links</Link>
                  <ul>
                    <li><Link to='tel:#'><i className='icon-phone' />Email: team@mahoganyacc.com</Link></li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className='header-right'>
              <div className='social-icons social-icons-color'>
                <a href='https://www.facebook.com/Mahoganyacc123' className='social-icon social-facebook' rel='noopener noreferrer' title='Facebook' target='_blank'><i className='icon-facebook-f' /></a>
                <a href='https://www.instagram.com/mahoganysclassycloset/' className='social-icon social-instagram' rel='noopener noreferrer' title='Instagram' target='_blank'><i className='icon-instagram' /></a>
              </div>
              <ul className='top-menu top-link-menu'>
                <li>
                  <Link to='#'>Links</Link>
                  <ul>
                    <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=account`} ><i className='icon-user' />Login</Link></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='header-middle'>
          <div className='container'>
            <div className='header-left'>
              <Logo logo='demos/demo-6/logo.png'svg />
            </div>

            <div className='header-right'>
              <Link to={`${process.env.PUBLIC_URL}/shop/wishlist`} className='wishlist-link'>
                <i className='icon-heart-o' />
                <span className='wishlist-count'>{this.props.wishlist.length}</span>
                <span className='wishlist-txt'>My Favorites</span>
              </Link>
              <CartMenu />
            </div>
          </div>
        </div>

        <div className='header-bottom sticky-header'>
          <div className='container'>
            <div className='header-left'>
              <button className='mobile-menu-toggler'>
                <span className='sr-only'>Toggle mobile menu</span>
                <i className='icon-bars' />
              </button>
              <MainMenu />
            </div>

            <div className='header-right'>
              <i className='la la-lightbulb-o' /><p>SALE<span className='highlight'>&nbsp;10% Off all orders at checkout</span></p>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

function mapStateToProps (state) {
  return {
    wishlist: state.wishlist.list
  }
}
export default connect(mapStateToProps, { showModal })(HeaderEight)
