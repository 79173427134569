import React from 'react'
import { Link } from 'react-router-dom'

import { safeContent } from '../../../utils/utils'

export default (props) => {
  const { image, subtitle, title, btnText = 'Discover More', link } = props.slide
  const defaultLink = `${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth`
  const linkTo = link ? defaultLink + link : defaultLink
  return (
    <div className='intro-slide' style={{ backgroundImage: `url(${image})` }}>
      <div className='container intro-content text-center'>
        <h3 className='intro-subtitle text-white'>{subtitle}</h3>
        <h1 className='intro-title text-white' dangerouslySetInnerHTML={safeContent(title)} />

        <Link to={linkTo} className='btn btn-outline-white-4'>
          <span>{btnText}</span>
        </Link>
      </div>
    </div>
  )
}
