import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { mobileMenu } from '../../../../../utils/utils'

class MobileMainNav extends Component {
  componentDidMount() {
    mobileMenu()
  }

  render() {
    return (
      <nav className='mobile-nav'>
        <ul className='mobile-menu'>
          <li>
            <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
          </li>

          <li>
            <Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth`}>Shop</Link>
            <ul>
              <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth`}>All Products</Link></li>
              <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Pewter-%26-etc-c72577765`}>Pewter & etc</Link></li>
              <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Earrings-c72605401`}>Earings</Link></li>
              <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Jewelry-c72604429`}>Jewelry</Link></li>
              <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Coats-Jackets-c72645143`}>Coats/Jackets</Link></li>
              <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Purses-c73654125`}>Purses</Link></li>
              <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Clothing-c106531038`}>Clothing</Link></li>
              <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=Bargain-Closet-$10-and-under-c121708263`}>$10 and Under</Link></li>
            </ul>
          </li>

          <li>
            <Link to='#'>Pages</Link>
            <ul>
              <li>
                <Link to={`${process.env.PUBLIC_URL}/pages/about`} className='sf-with-ul'>About</Link>
              </li>
              <li>
                <Link to={`${process.env.PUBLIC_URL}/pages/contact`} className='sf-with-ul'>Contact</Link>
              </li>
              <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=account`}>Login</Link></li>
              <li><Link to={`${process.env.PUBLIC_URL}/pages/faq`}>FAQs</Link></li>
            </ul>
          </li>
        </ul>
      </nav>
    )
  }
}

export default MobileMainNav
