import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ScrollContext } from 'react-router-scroll-4'
import 'react-app-polyfill/ie11'

// Store
import store from './store'

// Actions
import { getAllProducts, closeQuickViewModal, fetchEcwidCart } from './actions'

// Layouts
import Layout from './components/app'

// Demoes

import Index6 from './components/demoes/index6'

// Pages
import PageNotFound from './components/main/pages/404'
import AboutOne from './components/main/pages/about-1'
import ContactOne from './components/main/pages/contact-1'
import FAQ from './components/main/pages/faq'
import ComingSoon from './components/main/pages/coming-soon'
import Cart from './components/main/shop/cart'
import Wishlist from './components/main/shop/wishlist'

import FullwidthProduct from './components/main/product/fullwidth'
import MasonryProduct from './components/main/product/masonry'

// Shop
import NoSideBar from './components/main/shop/nosidebar'
import ShopMarket from './components/main/shop/market'
import ProductCategory from './components/main/shop/product-category'


// Landing Page
import Landing from './components/landing'
import Documentation from './components/docs'

// import Utils
import { initFunctions } from './utils/utils'

export class Root extends Component {
  constructor(props) {
    super(props)
    initFunctions()
  }

  componentDidMount() {
    // close quick view modal if it is opened in new page
    store.dispatch(closeQuickViewModal())
  }

  render() {
    store.dispatch(getAllProducts())
    store.dispatch(fetchEcwidCart())

    return (
      <Provider store={store}>
        <BrowserRouter basename='/'>
          <ScrollContext>
            <Switch>
              {/* Demoes and Individual Pages */}
              <Route exact path={`${process.env.PUBLIC_URL}/`} component={Index6} />

              <Route exact path={`${process.env.PUBLIC_URL}/pages/coming-soon`} component={ComingSoon} />
              <Route exact path={`${process.env.PUBLIC_URL}/product/fullwidth/:id`} component={FullwidthProduct} />
              <Route exact path={`${process.env.PUBLIC_URL}/shop/nosidebar/:type`} component={NoSideBar} />
              {/* <Route exact path={`${process.env.PUBLIC_URL}/shop/market`} component={ShopMarket} />
              <Route exact path={`${process.env.PUBLIC_URL}/shop/category/:type`} component={ProductCategory} /> */}
              <Layout>

                {/* Pages belonged in Page */}
                <Route exact path={`${process.env.PUBLIC_URL}/pages/404`} component={PageNotFound} />
                <Route exact path={`${process.env.PUBLIC_URL}/pages/about`} component={AboutOne} />
                <Route exact path={`${process.env.PUBLIC_URL}/pages/contact`} component={ContactOne} />
                <Route exact path={`${process.env.PUBLIC_URL}/pages/faq`} component={FAQ} />
                <Route exact path={`${process.env.PUBLIC_URL}/shop/cart`} component={Cart} />
                <Route exact path={`${process.env.PUBLIC_URL}/shop/wishlist`} component={Wishlist} />

              </Layout>
            </Switch>
          </ScrollContext>
        </BrowserRouter>
      </Provider>
    )
  }
}

ReactDOM.render(<Root />, document.getElementById('root'))
