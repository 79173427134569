import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../logo'


class FooterOne extends Component {

  constructor() {
    super();
    this.state = { screenWidth: null };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions()); 
    this.updateWindowDimensions()
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth });
  }

  render() {
    const { screenWidth } = this.state
    const { adClass = '', logo = 'assets/images/logo.png', border = true, width = '105', height = '25' } = this.props

    return (
      <footer className={`footer ${adClass}`}>
        {this.props.children}
        <div className={`footer-middle ${border ? '' : 'border-0'}`}>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-4 col-lg-3'>
                <div className='widget widget-about'>
                  {/* <img src={process.env.PUBLIC_URL + '/' + logo} className='footer-logo' alt='Footer Logo' width={width} height={height} /> */}
                  <p> Mahogany's Classy Closet is a high end moderate and affordable shop for coats, clothing, purses, jewelry, and accessories. </p>
                  <div className='social-icons'>
                    <a href='https://www.facebook.com/Mahoganyacc123' rel='noopener noreferrer' className='social-icon' target='_blank' title='Facebook'><i className='icon-facebook-f' /></a>
                    <a href='https://www.instagram.com/mahoganysclassycloset/' rel='noopener noreferrer' className='social-icon' target='_blank' title='Instagram'><i className='icon-instagram' /></a>
                  </div>
                </div>
              </div>

              <div className='col-sm-4 col-lg-3'>
                <div className='widget'>
                  <h4 className='widget-title'>My Account</h4>

                  <ul className='widget-list'>
                    <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=account`}>Sign In</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/shop/cart`}>View Cart</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/pages/faq`}>Help</Link></li>
                  </ul>
                </div>
              </div>

              <div className='col-sm-4 col-lg-6'>
                <div className={`d-flex ${screenWidth < 559 ? "justify-content-star" : "justify-content-end"} `} >
                  <Logo logo='demos/demo-6/logo.png'svg />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='footer-bottom'>
          <div className='container'>
            <p className='footer-copyright'>Copyright © {(new Date()).getFullYear()} MAHOGANY Store. All Rights Reserved.</p>
            <figure className='footer-payments'>
              <img src={`${process.env.PUBLIC_URL}/assets/images/payments.png`} alt='Payment methods' width='272' height='20' />
            </figure>
          </div>
        </div>
      </footer>
    )
  }
}

export default FooterOne
