import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

class Breadcrumb extends Component {
  render () {
    const { title, demo, products, adClass, type = 'normal', slug = 'default', container = 'container', productId, ...parent } = this.props
    const path = []
    let x, prevProducts, prevProduct, currentProducts, nextProducts, nextProduct

    for (x in parent) {
      if (typeof parent[x] !== 'function') { path.push(parent[x]) }
    }

    currentProducts = products.items.filter(item => item.id === productId)

    // get product for prev button.
    prevProducts = products.items.filter(item => item.id < parseInt(productId) && item.hdThumbnailUrl)
    if (!prevProducts || !prevProducts.length) { prevProduct = currentProducts[0] } else { prevProduct = prevProducts[prevProducts.length - 1] }

    // get product for next button.
    nextProducts = products.items.filter(item => item.id > parseInt(productId) && item.hdThumbnailUrl)
    if (!nextProducts || nextProducts.length === 0) { nextProduct = currentProducts[0] } else { nextProduct = nextProducts[0] }

    return (
      <nav aria-label='breadcrumb' className={`breadcrumb-nav ${adClass}`}>
        { type === 'product'
            ? <div className={`${container}  d-flex align-items-center`}>

              <nav className='product-pager ml-auto' aria-label='Product'>
                <Link className='product-pager-link product-pager-prev' to={`${process.env.PUBLIC_URL}/${type}/${slug}/${prevProduct.id}`} aria-label='Previous' tabIndex='-1'>
                  <i className='icon-angle-left' />
                  <span>Prev</span>
                  {
                                prevProduct
                                  ? <div className='product-detail'>
                                    <figure>
                                      <img src={prevProduct.imageUrl} alt='Product' />
                                    </figure>
                                    <h3 className='product-name'>{prevProduct.name}</h3>
                                  </div> : ''
                            }
                </Link>

                <Link className='product-pager-link product-pager-next' to={`${process.env.PUBLIC_URL}/${type}/${slug}/${nextProduct.id}`} aria-label='Next' tabIndex='-1'>
                  <span>Next</span>
                  <i className='icon-angle-right' />
                  {
                                nextProduct
                                  ? <div className='product-detail'>
                                    <figure>
                                      <img src={nextProduct.imageUrl} alt='Product' />
                                    </figure>
                                    <h3 className='product-name'>{nextProduct.name}</h3>
                                  </div> : ''
                            }
                </Link>
              </nav>
              </div> : ''}
      </nav>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    products: state.data.products ? state.data.products : [],
    demo: state.demo.current
  }
}

export default connect(mapStateToProps)(Breadcrumb)
