import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// Common Header Components
import Logo from '../logo'
import MainMenu from './common/main-menus/menu-1'
import SearchToggle from './common/search-toggle'
import CompareMenu from './common/compare-menus/menu-1'
import CartMenu from './common/cart-menus/menu-1'
import LoginModal from '../../features/login-modal'

import { showModal } from '../../../actions'
import { stickyHeaderHandler } from '../../../utils/utils'

class HeaderOne extends Component {
  constructor (props) {
    super(props)
    this.openLoginModal = this.openLoginModal.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', stickyHeaderHandler)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', stickyHeaderHandler)
  }

  openLoginModal (e) {
    this.props.showModal('login')
    e.preventDefault()
  }

  render () {
    const { wishlist, container = 'container' } = this.props
    return (
      <header className='header'>
        <div className='header-top'>
          <div className={container}>
            <div className='header-right'>
              <ul className='top-menu'>
                <li>
                  <Link to='#'>Links</Link>
                  <ul>
                    <li><Link to='tel: '><i className='icon-phone' />Call: +401 390 0549</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/shop/wishlist`}><i className='icon-heart-o' />Wishlist <span>({wishlist.length})</span></Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/pages/about`}>About Us</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/pages/contact`}>Contact Us</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=account`}><i className='icon-user' />Login</Link></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='header-middle sticky-header'>
          <div className={container}>
            <div className='header-left'>
              <button className='mobile-menu-toggler'>
                <span className='sr-only'>Toggle mobile menu</span>
                <i className='icon-bars' />
              </button>
              <MainMenu />
            </div>
            <div className='header-right'>
              <CartMenu />
            </div>
          </div>
        </div>

        <LoginModal />
      </header>
    )
  }
}

function mapStateToProps (state) {
  return {
    wishlist: state.wishlist.list ? state.wishlist.list : []
  }
}
export default connect(mapStateToProps, { showModal })(HeaderOne)
