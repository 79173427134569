import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
// import Custom Components
import PageHeader from '../../common/page-header'
import Breadcrumb from '../../common/breadcrumb'

import { removeFromWishlist, addToCartFromWishlist } from '../../../actions'

class Wishlist extends Component {
  componentDidMount () {
    const items = document.querySelectorAll('.action-col .dropdown')

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      item.addEventListener('click', function (e) {
        item.classList.add('show')
        item.querySelector('.dropdown-menu').classList.add('show')
        item.querySelector('.dropdown-menu').setAttribute('style', 'position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 40px, 0px);')
        e.stopPropagation()
      })
    }
    document.addEventListener('click', this.hiddenHandle)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.hiddenHandle)
  }

  hiddenHandle (e) {
    const items = document.querySelectorAll('.action-col .dropdown')
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      item.classList.remove('show')
      item.querySelector('.dropdown-menu').classList.remove('show')
      item.querySelector('.dropdown-menu').setAttribute('style', '')
    }
  }

  render () {
    const { wishlist } = this.props

    return (
      <div className='main'>

        <PageHeader title='Favorites' subTitle='Shop' />
        <Breadcrumb title='Wishlist' parent1={['Shop', 'shop/sidebar/list']} />

        <div className='page-content'>
          <div className='container'>
            <table className='table table-wishlist table-mobile'>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Stock Status</th>
                  <th />
                  <th />
                </tr>
              </thead>

              <tbody>
                {
                                    wishlist.map((item, index) =>

                                      <tr key={index}>
                                        <td className='product-col'>
                                          <div className='product'>
                                            <figure className='product-media'>
                                              <Link to={`${process.env.PUBLIC_URL}/product/default/${item.id}`}>
                                                <img src={process.env.PUBLIC_URL + '/' + item.originalImageUrl} alt='Product' />
                                              </Link>
                                            </figure>

                                            <h3 className='product-title'>
                                              <Link to={`${process.env.PUBLIC_URL}/shop/nosidebar/fullwidth?store-page=${item.name.split(" ").join("-")}-p${item.id}`}>
                                                {item.name}
                                              </Link>
                                            </h3>
                                          </div>
                                        </td>

                                        <td className='stock-col'>
                                          <span className={item.inStock ? 'in-stock' : 'out-of-stock'}>
                                            {item.price}
                                          </span>
                                        </td>


                                        <td className='stock-col'>
                                          <span className={item.inStock ? 'in-stock' : 'out-of-stock'}>
                                            {item.inStock ? 'In stock' : 'Out of stock'}
                                          </span>
                                        </td>
                                        <td className='remove-col'>
                                          <button
                                            className='btn-remove'
                                            onClick={(e) => this.props.removeFromWishlist(item.id)}
                                          >
                                            <i className='icon-close' />
                                          </button>
                                        </td>
                                      </tr>

                                    )
                                }

              </tbody>
            </table>
            <div className='wishlist-share'>
              <div className='social-icons social-icons-sm mb-2'>
                <label className='social-label'>Share on:</label>
                <a href='https://www.facebook.com/' className='social-icon' target='_blank' rel='noopener noreferrer' title='Facebook'><i className='icon-facebook-f' /></a>
                <a href='https://twitter.com/' className='social-icon' target='_blank' rel='noopener noreferrer' title='Twitter'><i className='icon-twitter' /></a>
                <a href='https://instagram.com/' className='social-icon' target='_blank' rel='noopener noreferrer' title='Instagram'><i className='icon-instagram' /></a>
                <a href='https://youtube.com/' className='social-icon' target='_blank' rel='noopener noreferrer' title='Youtube'><i className='icon-youtube' /></a>
                <a href='https://pinterest.com/' className='social-icon' target='_blank' rel='noopener noreferrer' title='Pinterest'><i className='icon-pinterest' /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state) => ({
  wishlist: state.wishlist.list
})

export default connect(mapStateToProps, { removeFromWishlist, addToCartFromWishlist })(Wishlist)
